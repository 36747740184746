/**
 * @description 路由信息
 */

export default [
  {
    name: "index",
    path: "/",
    component: () => import("@/views/index"),
    meta: {
      name: "index",
    },
  }
];
