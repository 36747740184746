<template>
  <div class="footer">
    <div
      class="bot-btn"
      v-for="(item, index) in btnName"
      :class="index == $parent.btnIndex ? 'bot-btns' : ''"
      @click="changeBtn(item, index)"
    >
      <div class="btn-img">
        <img
          :src="index == $parent.btnIndex ? item.imgASrc : item.imgSrc"
          alt=""
        />
      </div>
      <div class="name">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cus-footer",
  data() {
    return {
      btnName: [
        {
          imgSrc: require("@/assets/footer/f-m.svg"),
          imgASrc: require("@/assets/footer/f-a.svg"),
          name: "Features",
        },
        {
          imgSrc: require("@/assets/footer/p-m.svg"),
          imgASrc: require("@/assets/footer/p-a.svg"),
          name: "Parameter",
        },
        {
          imgSrc: require("@/assets/footer/d-m.svg"),
          imgASrc: require("@/assets/footer/d-a.svg"),
          name: "Details",
        },
        {
          imgSrc: require("@/assets/footer/s-m.svg"),
          imgASrc: require("@/assets/footer/s-a.svg"),
          name: "Structure",
        },
      ],
    };
  },
  methods: {
    changeBtn(item, i) {
      this.$parent.btnIndex = i;
      this.$emit("changeName", item);
      this.$emit("unDisplayMesh", item, true);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  width: 100%;
  height: 49px;
  display: flex;
  align-items: center;
  pointer-events: all;
  background-color: #fff;
  z-index: 101;
  .bot-btn {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .btn-img {
      width: 24px;
      height: 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.8);
    }
  }
  .bot-btns {
    .name {
      color: #007aff;
    }
  }
}
</style>
