/**
 * @description 自动注入全局指令
 */


import Vue from "vue"
import autoAnimation from "./auto-animation"
Vue.directive('rc',autoAnimation)



