/**
 * @description 公用工具类
 */
let timer = {}
/**
 * @description 获取token
 */
export const getToken = () => localStorage.getItem("token");

/**
 * @description 设置token
 */
export const setToken = (token = "") => localStorage.setItem("token", token);

/**
 * @description 手机号正则匹配
 */
export const testPhone = (phone) => /^1[3|4|5|7|8][0-9]{9}$/.test(phone);

/**
 * @description 平滑滚动
 */
export const linearScroll = (element, top) => {
  let scrollTop = element.scrollTop;
  const step = function () {
    let distance = top - scrollTop;

    scrollTop = scrollTop + distance / 20;
    if (Math.abs(distance) < 1) {
      element.scrollTo(0, top);
    } else {
      element.scrollTo(0, scrollTop);
      setTimeout(step, 1);
    }
  };
  step();
};
/**
 * @description 自动滚动
 */
export const Scroll = (element, id) => {
  //滚动的速度
  const speed = 40;
  //滚动距离
  const length = 1;
  if (!element) return
  else {
    timer[id] = setInterval(() => {
      let current = element.scrollTop;
      let target = 0;
      if (Math.abs(current + element.clientHeight - element.scrollHeight) < 2) {
        target = 0;
        element.scrollTop = 0;
      } else {
        target = current + length;
      }
      element.scrollTo(0, target);
    }, speed);
  }

};
export const Stop = (id) => {
  // console.log(timeValue,'time');
  clearInterval(timer[id]);
  timer[id] = null;
};
import Cookies from "js-cookie";
/**
 * @description 获取本地cookie
 */

export const getCookie = (name) => Cookies.get(name);

export function copyText(text) {
  // 动态创建 textarea 标签
  const textarea = document.createElement('textarea')
  // 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
  textarea.readOnly = 'readonly'
  textarea.style.position = 'absolute'
  textarea.style.left = '-9999px'
  // 将要 copy 的值赋给 textarea 标签的 value 属性
  textarea.value = text
  // 将 textarea 插入到 body 中
  document.body.appendChild(textarea)
  // 选中值并复制
  textarea.select()
  const result = document.execCommand('Copy')
  document.body.removeChild(textarea)
  return result
}
