// const console = {
//   log: () => { }
// }

import { Utils } from "run-scene-v2";
import bus from "./../../lib/bus";
import { partPointerName } from "./const";

// 声明变量
let camera, scene, controls, renderer2, renderer, dom, t, p, runScene, Bus;
// 工具
const { getMacro } = Utils;

// 拿资源
const setAssets = (assets) => {
  camera = assets.camera;
  scene = assets.scene;
  controls = assets.controls;
  renderer = assets.renderer;
  dom = assets.engineDom;
  t = assets.t;
};

//  页面接口总出口
function Change(runScene, onDone) {
  setAssets({ ...runScene.assetsEx.get(), t: this, runScene });

  t.runScene = runScene;

  t.onDone = onDone;

  // 场景事件
  this.events = null;
  // 工具
  this.tool = null;

  // 最后一帧加载回调
  t.runScene.on("complete", async () => {
    this.events = new Events();

    this.tool = new Tool();

    this._AngleView = new AngleView();
    this._AngleView.init();
   
    // const model = t.runScene.modelEx.getModel("线");
    // model.visible=false;

    this.size = new Size();
    this.size.init();


    // 最大距离
    // controls.maxDistance = 2000;


    //是否开启右键拖拽
    // controls.enablePan = false;

    t.runScene.renderEx.setSize();

    controls.maxPolarAngle = Math.PI / 2;

    // 基本配置
    (() => {
      t.runScene.script.playAll();
      t.runScene.cameraEx.setTemp('手机', { time: 1 });

      // 入场动画

      // bus.$on('setSceneSize', () => t.runScene.renderEx.setSize())

      // 加载回调
      t.onDone();
    })();
  });

  // 销毁
  this.dispose = () => runScene.dispose();
}
// 视角 切换
class AngleView {

  // 初始化 dom 信息
  initDomInfo = null;
  init() {
    // 根据 不同支架 ui位置切换状态
    /**
     * @params <string> lawn wall Balcony
     * @params <string> low height
     */
    bus.$on("SwitchAccordingToStatus", this.switchAccordingToStatus.bind(this));

    const infoDom = document.querySelector('.info');

    this.infoDomDate = infoDom.getBoundingClientRect();

    // 初始化位置
    this.switchAccordingToStatus(null, 'height')
  }
  // 根据状态切换
  switchAccordingToStatus(bracketName, state) {
    console.log('执行:---', bracketName, state);
    const infoDom = document.querySelector('.info');
    const infoDomDate = infoDom.getBoundingClientRect();
    const threePage = document.querySelector('.three-scene');
    const threePageDate = threePage.getBoundingClientRect();
    // const displayWindowHight = threePageDate.height - infoDomDate.height;
    // const displayWindowHight = threePageDate.height - 356;
    const displayWindowHight = threePageDate.height -
      393.3999938964844;
    const moveHight = (threePageDate.height - displayWindowHight) / 2;
    threePage.style.marginTop = `${state === 'height' ? -moveHight : 0}px`
    // threePage.style.marginTop = `${state === 'height' ? -this.infoDomDate.height : 0}px`

    // 相机 聚焦动画
    this.cameraAnima(bracketName, state)
  }

  cameraAnima(bracketName, state) {
    if (!bracketName) return
    const animaName = `${bracketName}-手机${state === 'height' ? 1 : 2
      }`;
    t.runScene.cameraEx.setTemp(animaName, { time: 1 });
  }
}

// 选择 模型
class ChoiceModel {
  // 电池模组 模型
  batteryModelMap = {};
  init() {
    // 获取模型
    this.getModel();
    // 切换模型
    bus.$on("switchMesh", this.choose.bind(this));
  }

  // 获取 模型
  getModel() {
    const tagsId = t.runScene.tags.get("LFP3000/HV");
    tagsId.map((childId) => {
      const model = t.runScene.modelEx.getById(childId);
      const childrenName = model.name;
      this.batteryModelMap[childrenName] = null;
      this.batteryModelMap[childrenName] = model;
    });
  }

  // 选择
  choose(chooseName, isStructure) {
    // 显示隐藏 对应电池
    Object.keys(this.batteryModelMap).map((modelName) => {
      const model = this.batteryModelMap[modelName];
      if (modelName === chooseName) {
        model.visible = true;
      } else {
        if (!model.visible) return;
        model.visible = false;
      }
    });

    // 重置 时间线状态
    t.dispose.resetGroup("组1");

    // 聚焦 动画
    this.focus(chooseName, isStructure);
  }

  // 聚焦 动画
  focus(chooseName, isStructure) {
    // const animaName = `${ chooseName } -pc初始`;
    const animaName = `${chooseName}-手机${isStructure === '相机2' ? 2 : 1}`;
    t.runScene.cameraEx.setTemp(animaName, { time: 1 });
  }
}


// 拆解相关
class Dispose {
  // 时间线 映射表
  timeLineMap = {
    "LFP3000/HV": [
      "LFP_9kWhHV",
      "LFP_12kWhHV",
      "LFP_15kWhHV",
      "LFP_18kWhHV",
      "LFP_21kWhHV",
      "LFP_24kWhHV",
      "LFP_27kWhHV",
      "LFP_30kWhHV",
    ],
    LFP5000: ["LFP_5000"],
    "LFP5-10kWHLV": ["LFP_5kWhLV", "LFP_10kWhLV"],
  };
  init() {
    // 拆解
    bus.$on("displayMesh", this.dispose.bind(this));
    // 恢复
    bus.$on("unDisplayMesh", this.reset.bind(this));

    // 重置所有 (0秒)
    bus.$on("resetAllNow", this.resetAllNow.bind(this));
  }

  // 拆解  bus
  dispose(meshName) {
    // 指定 时间线
    Object.keys(this.timeLineMap).map((groupName) => {
      const meshArray = this.timeLineMap[groupName];
      if (meshArray.includes(meshName)) {
        t.runScene.timeLineEx.playGroup(groupName);
      }
    });

    // scene.globalConfig.setGlow(true);

  }

  // 恢复 bus
  reset(meshName, isAll = false) {
    if (isAll) {
      // this.resetAll();
      this.resetAllNow()
    } else {
      // 指定 时间线
      Object.keys(this.timeLineMap).map((groupName) => {
        const meshArray = this.timeLineMap[groupName];
        if (meshArray.includes(meshName)) {
          t.runScene.timeLineEx.playGroup(groupName, { onlyReverse: true });
          // scene.globalConfig.setGlow(false);
        }
      });
    }
  }

  resetAll() {
    t.runScene.timeLineEx.playGroup("LFP3000/HV", { onlyReverse: true });
    t.runScene.timeLineEx.playGroup("LFP5000", { onlyReverse: true });
    t.runScene.timeLineEx.playGroup("LFP5-10kWHLV", { onlyReverse: true });
    // scene.globalConfig.setGlow(false);
  }

  // 重置 时间线状态
  resetGroup(sceneName) {
    t.runScene.timeLineEx.resetGroup(sceneName);
    // scene.globalConfig.setGlow(false);
  }

  // 重置所有 (0秒)
  resetAllNow() {
    t.runScene.timeLineEx.resetGroup("LFP3000/HV");
    t.runScene.timeLineEx.resetGroup("LFP5000");
    t.runScene.timeLineEx.resetGroup("LFP5-10kWHLV");
    // scene.globalConfig.setGlow(false);
  }

}

// 灯光
class Light {
  directionaLight = null;

  // 初始化
  init() {
    this.getModel();

    // 灯跟随移动
    t.runScene.cb.render.add("lightMove", () => {
      // this.directionaLight.position.copy(t.runScene.assetsEx.camera.position);
      // t.runScene.lightEx.update(this.directionaLight)
    });
  }

  // 获取 模型
  getModel() {
    this.directionaLight = t.runScene.modelEx.getModel("PointLight");
  }
}

// 尺寸
class Size {
  // 标签 名称
  labelModel = {};

  init() {
    this.getModel();
    // 展示 尺寸
    bus.$on("showSize", this.showSize.bind(this));
    // 隐藏 尺寸
    bus.$on("hiddenSize", this.hiddenSize.bind(this));
  }

  // 获取模型
  getModel() {
    return;
    const tagsId1 = t.runScene.tags.get("iphone标签");
    this.labelModel["iphone标签"] = {};
    tagsId1.map((childId) => {
      const model = t.runScene.modelEx.getById(childId);
      const childrenName = model.name;
      this.labelModel["iphone标签"][childrenName] = null;
      this.labelModel["iphone标签"][childrenName] = model;
      model.children.map((child) => {
        child.oldScale = {
          ...child.scale,
        };
      });
    });

  }

  // 展示尺寸
  showSize(meshName) {
    const model = t.runScene.modelEx.getModel("Group_标尺");
      model.children.map((child) => {
        Utils.anima(
          {
            x: 0,
            y: 0,
            z: 0,
          },
          {
            x: 1,
            y: 1,
            z: 1,
          },
          0.5,
          (data) => {
            child.scale.x = data.x;
            child.scale.y = data.y;
            child.scale.z = data.z;
            if (data.x >= 0.1) {
              if (model.visible) return;
              model.visible = true;
            }
          }
        );
      });
  }

  // 隐藏尺寸
  hiddenSize(meshName, isAll = false) {
      this.hiddenAllSize();
  }

  // 隐藏 全部
  hiddenAllSize() {
    const model = t.runScene.modelEx.getModel("Group_标尺");
    model.children.map((child) => {
        Utils.anima(
          {
            x: child.scale.x,
            y: child.scale.y,
            z: child.scale.z,
          },
          {
            x: 0,
            y: 0,
            z: 0,
          },
          0.5,
          (data) => {
            child.scale.x = data.x;
            child.scale.y = data.y;
            child.scale.z = data.z;
          },
          () => {
          }
        );
      });
  }
}


// 工具方法 ( 可封的公共方法 )
class Tool {
  // 相机 动画
  cameraAnima(position, time = 1, fn) {
    t.events.closeAnimaAtStart.cameraAnima = Utils.anima(
      {
        cx: t.runScene.assetsEx.camera.position.x,
        cy: t.runScene.assetsEx.camera.position.y,
        cz: t.runScene.assetsEx.camera.position.z,
        tx: t.runScene.assetsEx.controls.target.x,
        ty: t.runScene.assetsEx.controls.target.y,
        tz: t.runScene.assetsEx.controls.target.z,
      },
      {
        ...position,
      },
      time,
      (data) => {
        t.runScene.assetsEx.camera.position.set(data.cx, data.cy, data.cz);
        t.runScene.assetsEx.controls.target.set(data.tx, data.ty, data.tz);
        t.runScene.assetsEx.camera.updateProjectionMatrix();
        t.runScene.assetsEx.controls.update();
      },
      () => {
        fn && fn();
      }
    );
  }
  // 模型 透明度 渐变动画
  showAnima(info) {
    const { model, isShow, time, cb, opacity } = info;
    const models = [];
    model.traverse((m) => {
      if (m.type === "Group") return;
      if (m.type === "Object3D") return;
      m.material.transparent = true;
      isShow ? (m.material.opacity = 0) : null;
      models.push(m);
    });
    if (isShow) model.visible = isShow;
    Utils.anima(
      { opc: isShow ? 0 : opacity || 1 },
      { opc: isShow ? opacity || 1 : 0 },
      time,
      (data) => {
        models.map((m) => (m.material.opacity = data.opc));
      },
      () => {
        if (!isShow) model.visible = isShow;
        cb && cb();
      }
    );
  }
}

// 基本事件
class Events {
  downPosition = { x: 0, y: 0 };

  closeAnimaAtStart = { enterAnima: "" };

  constructor() {
    t.runScene.assetsEx.controls.addEventListener("start", this.controlStart);
    t.runScene.cb.model.setSelect.add(
      "trigger-click",
      this.triggerClick.bind(this)
    );
    t.runScene.cb.events.pointer.down.add("trigger", (e) =>
      t.runScene.modelEx.selectNull()
    );
    document.addEventListener("click", (e) => { });

    // this.autoRotate();
  }

  autoRotate() {
    t.runScene.cb.render.add("autoRotate", () => {
      t.runScene.assetsEx.controls.autoRotate = true;
      t.runScene.assetsEx.controls.autoRotateSpeed = 2;
      t.runScene.assetsEx.controls.update();
    });
  }

  triggerClick = (model) => {
    if (!model) return;
    console.log("点击的模型:", model);
    // console.log(
    //   `cx:${ camera.position.x }, cy:${ camera.position.y }, cz:${ camera.position.z }, tx:${ controls.target.x }, ty:${ controls.target.y }, tz:${ controls.target.z } `,
    //   "位置"
    // );
  };

  // 清除动画
  controlStart = () => {
    this.closeAnmia();
  };

  // 暂停动画 并清空内容 item就是那个动画
  closeAnmia() {
    Object.values(this.closeAnimaAtStart).map((i) => i && i.kill());
  }

  dispose() {
    controls.removeEventListener("start", this.controlStart);
  }
}


export default Change;
