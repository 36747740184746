// 部件 点位 名称
const partPointerName = [
    "26-R5-阳台-点位",
    "13-ATW33-S1-阳台-点位",
    "13-ATC30-S1-阳台-点位",
    "14-U40-XA2-阳台-点位",
    "14-U46-SA1-阳台-点位",
    "13-ATW33-S2-阳台-点位",
    "U-shaped_hoop-阳台-点位",
    "HE-14-L3-33-阳台-点位",
    "HE-36-F8-NS20-01-阳台-点位",
    "HE-36-F8-55-02-阳台-点位",
    "HE-18-EC3540-F25-阳台-点位",


    "HE-36-F8-NS20-01-草地-点位",
    "HE-36-F8-55-02-草地-点位",
    "13-ATC30-S1-草地-点位",
    "13-ATW33-S1-草地-点位",
    "14-U40-XA1-草地-点位",
    "14-U46-SA1-草地-点位",
    "13-ATW33-S2-草地-点位",
    "26-R5-草地-点位",
    "HE-14-L3-33-草地-点位",
    "HE-18-EC3540-F25-草地-点位",


    "HE-18-EC3540-F25-靠墙-点位",
    "HE-36-F8-NS20-01-靠墙-点位",
    "HE-36-F8-55-02-靠墙-点位",
    "13-ATC30-S1-靠墙-点位",
    "14-U40-XA1-靠墙-点位",
    "14-U46-SA1-靠墙-点位",
    "13-ATW33-S2-靠墙-点位",
    "36-K8-100-靠墙-点位",
    "13-ATW33-S1-靠墙-点位",
    "HE-14-L3-33-靠墙-点位",
    "26-R5-靠墙-点位",
];

export { partPointerName };
