<template>
  <div class="three-scene" ref="three-scene" onselectstart="return false;">
    <div @pointerdown="(e) => e.stopPropagation()" class="btn"></div>
  </div>
</template>

<script>
import { partPointerName } from "./const";
import store from "../../store/index";
// chang 事件 实例
let sceneChange = null;
// 场景
let scene = null;
import Change from "./Change";
import { RunScene } from "run-scene-v2";
import bus from "./../../lib/bus";
export default {
  name: "Scene",
  data() {
    return {
      partName: partPointerName,
      // hover 的 部件
      choosePartId: {
        name: "",
        isHover: false,
        isClick: false,
      },
      per: null,
      defaultStyle: {
        "--defaultTop": "200px",
      },
    };
  },
  mounted() {
    // 加载场景
    this.loadScene();
  },
  methods: {
    upCamera() {
      bus.$emit("reduceCameraPosition");
    },

    testSizeShow() {
      bus.$emit("displayMesh", "草地");
    },
    testSizeHidden() {
      bus.$emit("unDisplayMesh");
    },
    // 加载场景
    loadScene() {
      scene = new RunScene({
        msg: {
          // show: true,
        },
        showFps: true,
        coverSameId: true,
        instanceClone: false,
        // render3: true,
        // render2: true,
        renderConfig: {
          // 是否允许设置模型位置后自动渲染最新效果
          matrixAutoUpdate: true,
          scriptFrame: 60,
        },
      })
        .load({
          path:
            // 国内地址
            // 外网地址
            // 本地地址
            // "http://192.168.3.8:8080/file?path=project/linkpoint/&key=202302141356396090191001202374",
            // "https://test2-1303915342.cos.ap-shanghai.myqcloud.com/sakt/s.glb",
            // "http://192.168.3.8:8080/file?path=project/linkpoint/&key=202303041600202957111001202325",
            // 'http://192.168.3.8:8080/file?path=project/linkpoint/&key=202303241025029864021001202395',
            "./scene.glb",
          dom: this.$refs["three-scene"],
        })
        .on("complete", () => {
          console.log(scene, "scene");
          scene.globalConfig.setGlow(false);
        });
      // 实例化场景
      sceneChange = new Change(scene, this.onDone);
    },
    // 加载完回调
    onDone() {
      console.log("场景加载完毕~");
      this.$emit("loading");
    },
    // 打印点击到的模型
    logClickModel(model) {
      console.log("点击的模型为:", model.name);
    },
    // 聚焦 部件
    focuseParts(partId) {
      // const id = partId.substring(0, partId.indexOf("点位") - 4);
      // bus.$emit("switchMeshChild", store.state.mesh, id);
      // 3d选择模型零件
      // bus.$emit("switchMeshChild3D", store.state.mesh, id);
    },
  },
  // 场景自带销毁
  unmounted() {
    // sceneChange && sceneChange.dispose();
    scene && scene.dispose();
  },
};

// 导出场景 资源
export { sceneChange, scene };
</script>

<style scoped lang="scss">
.three-scene {
  width: 100%;
  height: 100%;
  position: relative;
  transition: all 0.5s ease;
}

.three-scene .btn {
  position: absolute;
  z-index: 2;
}

.three-scene .show {
  opacity: 1 !important;
}

.three-scene .none {
  opacity: 0 !important;
}

.three-scene .block {
  display: block !important;
}

.sprites {
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: red;
  opacity: 0;
}

.showOpacity {
  opacity: 1 !important;
}

.partSprite {
  opacity: 0;
  width: 16px;
  height: 16px;
  z-index: 3;
  border-radius: 50%;
  position: relative;

  .outerCircle {
    width: 100%;
    height: 100%;
    font-size: 12px;
    background: rgba(255, 255, 255, 0.3);
    border: 0.1px solid rgba(255, 255, 255, 0.8);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: -1;
  }

  .InnerRing {
    width: 8px;
    height: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    position: absolute;
    z-index: -1;
  }

  .text {
    left: 200%;
    width: 0px;
    // background-color: red;
    margin-top: -100%;
    border-radius: 60px;
    transition: all 0.5s;
    overflow: hidden;
    z-index: 4;

    > div {
      // width: 100%;
      // height: 100%;
      transition: all 0.4s;
      border-radius: 60px;
      width: 161px;
      height: 40px;
      margin-left: -100px;
      opacity: 0;
      color: rgba(0, 0, 0, 0.8);
      background: rgba(255, 255, 255, 0.8);
      border-radius: 60px;
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
